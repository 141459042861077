import React from "react";
import CongratulationCard from "../work/Conguratulations";

function GoogleForms() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 p-6">
      <CongratulationCard />
      <h1 className="text-3xl font-bold text-white mb-6">Our Premium Form</h1>
      <div className="bg-gray-800 shadow-2xl rounded-lg overflow-hidden w-full max-w-4xl">
        <iframe
          title="Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLScY1_ZFKeTFKUs8-6qMLqVmpugVjUCZu0MaZ7JSYF3czT_rwQ/viewform?embedded=true"
          className="w-full h-[800px] border-0"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default GoogleForms;