export const categoriesData = {
  "Electronics": {
    "Mobile Phones": [
      "Brand", "Model", "Price", "Operating System", "Screen Size", "Battery Capacity",
      "RAM", "Storage", "Camera Resolution", "Warranty Period",
      "Description", "State", "City"
    ],
    "Laptops": [
      "Brand", "Model", "Price", "Processor", "RAM", "Storage", "Screen Size",
      "Graphics Card", "Operating System", "Warranty Period",
      "Description", "State", "City"
    ],
    "Televisions": [
      "Brand", "Model", "Price", "Screen Size", "Resolution", "Smart TV",
      "HDMI Ports", "Refresh Rate", "Warranty Period", "Energy Rating",
      "Description", "State", "City"
    ],
    "Cameras": [
      "Brand", "Model", "Price", "Megapixels", "Lens Type", "Zoom", "Sensor Size",
      "ISO Range", "Video Resolution", "Warranty Period",
      "Description", "State", "City"
    ],
    "Audio": [
      "Brand", "Model", "Price", "Type (Speaker/Headphones)", "Wireless",
      "Battery Life", "Noise Cancellation", "Frequency Response", "Connectivity", "Warranty Period",
      "Description", "State", "City"
    ]
  },
  "Vehicles": {
    "Cars": [
      "Brand", "Model", "Price", "Year", "Mileage", "Fuel Type", "Transmission",
      "Engine Capacity", "Color", "Registration Number",
      "Description", "State", "City"
    ],
    "Motorcycles": [
      "Brand", "Model", "Price", "Year", "Mileage", "Engine Capacity", "Type",
      "Fuel Type", "Color", "Warranty Period",
      "Description", "State", "City"
    ],
    "Trucks": [
      "Brand", "Model", "Price", "Year", "Mileage", "Engine Capacity", "Load Capacity",
      "Fuel Type", "Transmission", "Warranty Period",
      "Description", "State", "City"
    ],
    "Bicycles": [
      "Brand", "Model", "Price", "Type (Road/Mountain/Hybrid)", "Frame Size",
      "Gear Count", "Brake Type", "Color", "Weight", "Warranty Period",
      "Description", "State", "City"
    ],
    "Scooters": [
      "Brand", "Model", "Price", "Year", "Mileage", "Engine Capacity", "Fuel Type",
      "Transmission", "Color", "Warranty Period",
      "Description", "State", "City"
    ]
  },
  "Real Estate": {
    "Residential": [
      "Property Type", "Location", "Price", "Area (sq.ft.)", "Bedrooms",
      "Bathrooms", "Furnishing Status", "Age of Property", "Parking Availability", "Additional Amenities",
      "Description", "State", "City"
    ],
    "Commercial": [
      "Property Type", "Location", "Price", "Area (sq.ft.)", "Floor", "Parking Availability",
      "Lift Facility", "Power Backup", "Security", "Additional Amenities",
      "Description", "State", "City"
    ],
    "Land": [
      "Location", "Price", "Area (acres/sq.ft.)", "Land Type", "Zoning",
      "Legal Clearances", "Accessibility", "Nearby Infrastructure", "Water Source", "Additional Notes",
      "Description", "State", "City"
    ],
    "Industrial": [
      "Property Type", "Location", "Price", "Area (sq.ft.)", "Load Capacity",
      "Power Supply", "Floor Type", "Security", "Transportation Access", "Additional Amenities",
      "Description", "State", "City"
    ],
    "Agricultural": [
      "Location", "Price", "Area (acres/sq.ft.)", "Soil Type", "Water Source",
      "Irrigation Facility", "Crop History", "Nearby Market", "Zoning", "Additional Notes",
      "Description", "State", "City"
    ]
  },
  "Fashion": {
    "Men": [
      "Brand", "Item Type", "Price", "Size", "Color", "Material", "Style",
      "Occasion", "Fit", "Care Instructions",
      "Description", "State", "City"
    ],
    "Women": [
      "Brand", "Item Type", "Price", "Size", "Color", "Material", "Style",
      "Occasion", "Fit", "Care Instructions",
      "Description", "State", "City"
    ],
    "Kids": [
      "Brand", "Item Type", "Price", "Size", "Color", "Material", "Style",
      "Occasion", "Age Group", "Care Instructions",
      "Description", "State", "City"
    ],
    "Accessories": [
      "Brand", "Item Type", "Price", "Color", "Material", "Design", "Occasion",
      "Dimensions", "Weight", "Warranty",
      "Description", "State", "City"
    ],
    "Footwear": [
      "Brand", "Item Type", "Price", "Size", "Color", "Material", "Style",
      "Occasion", "Sole Type", "Care Instructions",
      "Description", "State", "City"
    ]
  },
  "Jobs": {
    "Full-time": [
      "Job Title", "Company Name", "Location", "Salary", "Experience Required",
      "Job Type", "Industry", "Skills Required", "Education Required", "Application Deadline",
      "Description", "State", "City"
    ],
    "Part-time": [
      "Job Title", "Company Name", "Location", "Salary", "Working Hours",
      "Experience Required", "Skills Required", "Industry", "Education Required", "Application Deadline",
      "Description", "State", "City"
    ],
    "Freelance": [
      "Job Title", "Company Name", "Project Duration", "Budget", "Skills Required",
      "Experience Level", "Industry", "Work Mode (Remote/Onsite)", "Education Required", "Application Deadline",
      "Description", "State", "City"
    ],
    "Internship": [
      "Job Title", "Company Name", "Location", "Stipend", "Duration",
      "Skills Required", "Experience Level", "Industry", "Education Required", "Application Deadline",
      "Description", "State", "City"
    ],
    "Work-from-home": [
      "Job Title", "Company Name", "Salary", "Working Hours", "Skills Required",
      "Experience Level", "Industry", "Education Required", "Application Deadline", "Remote Setup Requirements",
      "Description", "State", "City"
    ]
  },
  "Education/Training": {
    "Schooling": [
      "School Name", "Location", "Curriculum", "Fees", "Grades Available",
      "Facilities", "Admission Criteria", "Student-Teacher Ratio", "Extracurricular Activities", "Accreditation",
      "Description", "State", "City"
    ],
    "Higher Education": [
      "University Name", "Location", "Course Name", "Fees", "Duration",
      "Mode (Online/Offline)", "Scholarship Availability", "Accreditation", "Eligibility Criteria", "Admission Deadline",
      "Description", "State", "City"
    ],
    "Online Courses": [
      "Platform", "Course Name", "Price", "Duration", "Instructor",
      "Certification", "Skill Level", "Language", "Course Rating", "Additional Materials",
      "Description", "State", "City"
    ],
    "Skill Training": [
      "Training Provider", "Course Name", "Price", "Duration", "Mode",
      "Certification", "Skill Level", "Industry Focus", "Hands-on Training", "Placement Assistance",
      "Description", "State", "City"
    ],
    "Coaching Institutes": [
      "Institute Name", "Location", "Courses Offered", "Fees", "Duration",
      "Mode", "Past Results", "Faculty", "Study Materials", "Admission Criteria",
      "Description", "State", "City"
    ]
  },
  "Financial Services": {
    "Banking": [
      "Bank Name", "Account Type", "Interest Rate", "Minimum Balance", "Features",
      "Online Banking", "Customer Support", "Loan Availability", "Fees & Charges", "Eligibility",
      "Description", "State", "City"
    ],
    "Loans": [
      "Loan Type", "Lender", "Interest Rate", "Loan Amount", "Repayment Tenure",
      "Eligibility", "Processing Fees", "Approval Time", "Collateral Required", "Additional Benefits",
      "Description", "State", "City"
    ],
    "Insurance": [
      "Insurance Type", "Company", "Coverage Amount", "Premium", "Tenure",
      "Claim Process", "Eligibility", "Add-ons", "Exclusions", "Customer Support",
      "Description", "State", "City"
    ],
    "Investments": [
      "Investment Type", "Platform", "Expected ROI", "Risk Level", "Minimum Investment",
      "Lock-in Period", "Tax Benefits", "Withdrawal Process", "Additional Benefits", "Rating",
      "Description", "State", "City"
    ],
    "Tax Services": [
      "Service Provider", "Service Type", "Fees", "Eligibility", "Turnaround Time",
      "Documents Required", "Mode (Online/Offline)", "Government Compliance", "Support Availability", "Additional Notes",
      "Description", "State", "City"
    ]
  }
};
