import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { categoriesData } from "./categoriesData";
import { db } from "../../firebaseConfig";

// Predefined dropdown options
const dropdownOptions = {
  Price: [
    "Below ₹5,000",
    "₹5,000 - ₹10,000",
    "₹10,000 - ₹50,000",
    "₹50,000 - ₹1,00,000",
    "Above ₹1,00,000",
  ],
  Salary: [
    "Below ₹10,000",
    "₹10,000 - ₹20,000",
    "₹20,000 - ₹50,000",
    "₹50,000 - ₹1,00,000",
    "Above ₹1,00,000",
  ],
  Location: ["Delhi", "Mumbai", "Bangalore", "Hyderabad", "Lucknow", "Other"],
  State: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  Color: ["Red", "Blue", "Black", "White", "Gray", "Green"],
  "Fuel Type": ["Petrol", "Diesel", "CNG", "Electric"],
  Transmission: ["Manual", "Automatic"],
  "Property Type": ["Apartment", "Villa", "Independent House", "Plot"],
  "Service Type": ["One-time", "Weekly", "Monthly"],
};

function DynamicForm() {
  const [userProfile, setUserProfile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [fieldValues, setFieldValues] = useState({});
  const [message, setMessage] = useState("");

  // Fetch user profile from Firebase Auth / Firestore
  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUserProfile({
              fullName: `${data.firstName} ${data.lastName}`,
              email: data.email || currentUser.email,
            });
          } else {
            setMessage("User profile not found.");
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
          setMessage("Error fetching user profile.");
        });
    } else {
      setMessage("User not logged in.");
    }
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory("");
    setFieldValues({});
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    setFieldValues({});
  };

  const handleFieldChange = (fieldName, value) => {
    setFieldValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userProfile) {
      setMessage("User profile not loaded.");
      return;
    }
    if (!selectedCategory || !selectedSubcategory) {
      setMessage("Please select a category and subcategory.");
      return;
    }
    const expectedFields =
      categoriesData[selectedCategory][selectedSubcategory] || [];
    for (let field of expectedFields) {
      if (!fieldValues[field]) {
        setMessage(`Please fill in ${field}.`);
        return;
      }
    }

    const submissionData = {
      userName: userProfile.fullName,
      email: userProfile.email,
      category: selectedCategory,
      subcategory: selectedSubcategory,
      fields: fieldValues,
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, "submissions"), submissionData);
      setMessage("Form submitted successfully!");
      setSelectedCategory("");
      setSelectedSubcategory("");
      setFieldValues({});
    } catch (error) {
      console.error("Error saving submission:", error);
      setMessage("Error saving your submission.");
    }
  };

  const subcategories = selectedCategory
    ? Object.keys(categoriesData[selectedCategory])
    : [];
  const fields =
    selectedCategory && selectedSubcategory
      ? categoriesData[selectedCategory][selectedSubcategory]
      : [];

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-900 shadow-lg rounded-lg mt-8 border border-gray-700">
      <h2 className="text-2xl font-bold mb-4 text-white">
        Select The Appropriate Category To Post Your Ad
      </h2>
      {message && <p className="mb-4 text-red-400">{message}</p>}

      {!userProfile ? (
        <p className="text-gray-400">Loading user profile...</p>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block font-medium text-gray-300">
              Your Name:{" "}
              <span className="text-blue-400">{userProfile.fullName}</span>
            </label>
          </div>

          <div>
            <label className="block font-medium mb-1 text-gray-300">
              Select Category
            </label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="w-full bg-gray-800 border border-gray-700 p-2 rounded text-gray-300"
              required
            >
              <option value="">-- Choose a Category --</option>
              {Object.keys(categoriesData).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>

          {selectedCategory && (
            <div>
              <label className="block font-medium mb-1 text-gray-300">
                Select Subcategory
              </label>
              <select
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
                className="w-full bg-gray-800 border border-gray-700 p-2 rounded text-gray-300"
                required
              >
                <option value="">-- Choose a Subcategory --</option>
                {subcategories.map((subcat) => (
                  <option key={subcat} value={subcat}>
                    {subcat}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedSubcategory &&
            fields.map((fieldName, index) => (
              <div key={index}>
                <label className="block font-medium mb-1 text-gray-300">
                  {fieldName}
                </label>
                {dropdownOptions[fieldName] ? (
                  <select
                    value={fieldValues[fieldName] || ""}
                    onChange={(e) =>
                      handleFieldChange(fieldName, e.target.value)
                    }
                    className="w-full bg-gray-800 border border-gray-700 p-2 rounded text-gray-300"
                    required
                  >
                    <option value="">-- Select {fieldName} --</option>
                    {dropdownOptions[fieldName].map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    value={fieldValues[fieldName] || ""}
                    onChange={(e) =>
                      handleFieldChange(fieldName, e.target.value)
                    }
                    className="w-full bg-gray-800 border border-gray-700 p-2 rounded text-gray-300"
                    required
                  />
                )}
              </div>
            ))}

          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default DynamicForm;
