/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/dfehyjw5juj.png";
import iso from "../../assets/iso.png";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-800 text-white py-12 relative overflow-hidden">
      <div className="container mx-auto px-4">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Logo Section */}
          <div className="space-y-6">
            <img src={logo} alt="My Logo" className="h-12" />
            <p className="text-gray-400 text-sm leading-relaxed">
              Empowering businesses with innovative digital solutions. Created by Team Ambey Nexus with ❤️
            </p>
            <div className="flex items-center space-x-3">
              <img src={iso} alt="ISO Certified" className="h-16" />
              <span className="text-gray-300 font-semibold">ISO 9001:2015 Certified</span>
            </div>
          </div>

          {/* Services Section */}
          <div>
            <h4 className="text-lg font-bold mb-6 text-white border-l-4 border-blue-500 pl-3">Our Services</h4>
            <ul className="space-y-3">
              {["Website Development", "Graphics Designing", "Social Media Management", "SEO"].map((service, index) => (
                <li key={index}>
                  <Link to="/services" className="text-gray-400 hover:text-blue-400 transition-colors">
                    {service}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-bold mb-6 text-white border-l-4 border-blue-500 pl-3">Quick Links</h4>
            <ul className="space-y-3">
              {[
                { name: "Home", path: "/" },
                { name: "About", path: "/about" },
                { name: "Services", path: "/services" },
                { name: "Contact", path: "/contact" },
                { name: "Privacy Policy", path: "/privacy-policy" }
              ].map((link, index) => (
                <li key={index}>
                  <Link to={link.path} className="text-gray-400 hover:text-blue-400 transition-colors">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact & Social Media */}
          <div>
            <h4 className="text-lg font-bold mb-6 text-white border-l-4 border-blue-500 pl-3">Contact Us</h4>
            <div className="space-y-4">
              <div className="text-gray-400">
                <p>Phone: +91 7696637927</p>
                <p>Email: info@ambeynexus.com</p>
              </div>
              <Link to="/faq" className="inline-block text-gray-400 hover:text-blue-400 transition-colors">
                FAQ
              </Link>
              <div className="pt-4">
                <h5 className="text-lg font-bold mb-4 text-white">Follow Us</h5>
                <div className="flex space-x-4">
                  <a href="https://www.facebook.com/share/SUm6Vp1S7uW1suBc/?mibextid=qi2Omg" className="text-gray-400 hover:text-blue-500 transition-colors">
                    <FaFacebook className="w-6 h-6" />
                  </a>
                  <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
                    <FaTwitter className="w-6 h-6" />
                  </a>
                  <a href="https://www.instagram.com/ambeynexus?igsh=bjMxa2Vwend6d2l3" className="text-gray-400 hover:text-pink-600 transition-colors">
                    <FaInstagram className="w-6 h-6" />
                  </a>
                  <a href="#" className="text-gray-400 hover:text-blue-700 transition-colors">
                    <FaLinkedin className="w-6 h-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 3D Watermark */}
        <div className="border-t border-gray-700 pt-8 mt-8 relative">
          <div className="text-center">
            <div className="absolute inset-0 flex items-center justify-center">
              <div 
                className="text-6xl md:text-9xl font-bold text-gray-800 opacity-20 select-none transform perspective-1000 rotate-x-20 rotate-y-20"
                style={{
                  textShadow: `
                    -1px -1px 0 #4A5568,  
                    1px -1px 0 #4A5568,
                    -1px 1px 0 #4A5568,
                    1px 1px 0 #4A5568,
                    -2px -2px 0 #2D3748,
                    2px -2px 0 #2D3748,
                    -2px 2px 0 #2D3748,
                    2px 2px 0 #2D3748,
                    -4px -4px 8px rgba(0, 0, 0, 0.3),
                    4px 4px 8px rgba(0, 0, 0, 0.3)
                  `,
                  transform: "rotateX(20deg) rotateY(20deg) translateZ(0)",
                }}
              >
                Ambey Nexus
              </div>
            </div>
            <div className="relative">
              <p className="text-gray-400 text-sm">
                © {new Date().getFullYear()} Ambey Nexus. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;