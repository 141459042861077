import React from "react";

const NotAuthorized = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-zinc-900 flex items-center justify-center p-4">
      <div className="relative group max-w-2xl w-full text-center">
        {/* Glowing border effect */}
        <div className="absolute -inset-2 bg-gradient-to-r from-red-600 to-rose-800 rounded-2xl blur opacity-50 group-hover:opacity-80 transition-all duration-500 animate-pulse"></div>

        <div className="relative bg-black rounded-2xl p-12 border border-gray-800 shadow-2xl">
          {/* Animated X icon */}
          <div className="mb-8 flex justify-center">
            <div className="relative w-24 h-24">
              <div className="absolute inset-0 bg-red-500/20 rounded-full animate-ping"></div>
              <div className="relative flex items-center justify-center w-24 h-24 bg-red-600 rounded-full border-4 border-red-400/50">
                <svg
                  className="w-16 h-16 text-red-200 animate-bounce"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* Text content */}
          <h1 className="text-5xl font-black mb-4 bg-gradient-to-r from-red-400 to-rose-400 bg-clip-text text-transparent">
            ACCESS DENIED
          </h1>

          <p className="text-xl font-semibold text-gray-300 mb-8">
            UNAUTHORIZED ENTRY DETECTED
          </p>

          {/* Animated divider */}
          <div className="relative mb-8">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-red-400/30"></div>
            </div>
            <div className="relative flex justify-center">
              <span className="px-4 bg-black text-red-300 text-sm font-mono">
                SECURITY ALERT 401
              </span>
            </div>
          </div>

          {/* Action button */}
          <button
            onClick={() => window.history.back()}
            className="inline-flex items-center px-8 py-3 border border-red-600/50 bg-red-900/20 text-red-300 font-bold uppercase tracking-wider rounded-lg hover:bg-red-800/30 hover:border-red-400 transition-all duration-300"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Return to Safety
          </button>
        </div>

        {/* Background particles */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 bg-red-400 rounded-full animate-pulse"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.2}s`,
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
