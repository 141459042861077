import React from "react";

function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-gray-50 to-gray-100">
      {/* Spinner Container */}
      <div className="relative w-24 h-24">
        {/* Glow Effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full blur-lg opacity-30 animate-pulse"></div>

        {/* Spinner */}
        <div className="relative w-24 h-24">
          <div className="absolute inset-0 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
          <div className="absolute inset-0 border-4 border-pink-500 border-b-transparent rounded-full animate-spin-reverse"></div>
        </div>
      </div>
    </div>
  );
}

export default LoadingSpinner;