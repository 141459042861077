import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";
import logo from "../../assets/dfehyjw5juj.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log("Current User:", currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const activeClassName = "bg-orange-500 text-white rounded-lg px-4 py-2 transition";

  return (
    <nav className="bg-gray-800 p-4 h-24 relative">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div>
          <img src={logo} alt="My Logo" className="h-[60px] hover:cursor-pointer" />
        </div>

        {/* Hamburger icon for mobile */}
        <div className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
          <button className="text-white focus:outline-none">
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              ></path>
            </svg>
          </button>
        </div>

        {/* Full Nav Links for larger screens */}
        <div className="hidden md:flex space-x-8 items-center font-bold text-lg mr-4">
          {["Home", "About", "Services", "Career", "Blog", "Training","Demo-work", "Contact"].map(
            (item) => (
              <NavLink
                key={item}
                to={item === 'Home' ? '/home' : `/${item.toLowerCase().replace(" ", "")}`}
                className={({ isActive }) =>
                  isActive ? activeClassName : "text-white hover:scale-110 transition"
                }
              >
                {item}
              </NavLink>
            )
          )}

          {/* Profile Icon or Login/Signup */}
          {user ? (
            <NavLink to="/profile">
              <div className="w-12 h-12 bg-orange-500 text-white flex items-center justify-center rounded-full font-bold uppercase cursor-pointer">
                {user.displayName
                  ? user.displayName.charAt(0)
                  : user.email.charAt(0).toUpperCase()}
              </div>
            </NavLink>
          ) : (
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? activeClassName : "text-white hover:scale-110 transition"
              }
            >
              Login/Signup
            </NavLink>
          )}
        </div>
      </div>

      {/* Mobile Sidebar (Sliding from Right) */}
      <div
        className={`fixed top-0 right-0 w-[60%] h-full bg-[hsl(238,72%,55%)] text-white flex flex-col items-center space-y-8 py-12 text-xl shadow-lg transition-transform duration-300 z-50 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          className="absolute top-5 right-5 text-white text-3xl"
          onClick={handleCloseMenu}
        >
          &times;
        </button>

        {/* Profile Icon or Login/Signup */}
        {user ? (
          <NavLink to="/profile" onClick={handleCloseMenu}>
            <div className="w-12 h-12 bg-orange-500 text-white flex items-center justify-center rounded-full font-bold uppercase cursor-pointer">
              {user.displayName
                ? user.displayName.charAt(0)
                : user.email.charAt(0).toUpperCase()}
            </div>
          </NavLink>
        ) : (
          <NavLink
            to="/profile"
            onClick={handleCloseMenu}
            className="text-white text-2xl font-semibold hover:scale-110 transition"
          >
            Login/Signup
          </NavLink>
        )}

        {["Home", "About", "Services","Demo-work", "Career", "Blog", "Training", "Contact"].map(
          (item) => (
            <NavLink
              key={item}
              to={`/${item.toLowerCase().replace(" ", "")}`}
              onClick={handleCloseMenu}
              className="text-white text-2xl font-semibold hover:scale-110 transition"
            >
              {item}
            </NavLink>
          )
        )}

        
      </div>
    </nav>
  );
};

export default Navbar;
