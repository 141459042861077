// import React from "react";
// import { toast } from "react-toastify";

// const eBooks = [
//   {
//     id: 1,
//     title: "Master Work From Home Technique",
//     description: "Comprehensive guide from fundamentals to advanced concepts",
//     price: "₹199",
//     rating: 4.5,
//     sellerTag: "best seller",
//     image:
//       "https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
//   },
//   {
//     id: 5,
//     title: "Master JavaScript",
//     description: "Comprehensive guide from fundamentals to advanced concepts",
//     price: "₹500",
//     rating: 4.5,
//     sellerTag: "best seller",
//     image:
//       "https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
//   },
//   {
//     id: 4,
//     title: "Master JavaScript",
//     description: "Comprehensive guide from fundamentals to advanced concepts",
//     price: "₹999",
//     rating: 4.5,
//     sellerTag: "best seller",

//     image:
//       "https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
//   },
//   {
//     id: 2,
//     title: "React Pro Series",
//     description: "Modern React development with hooks and best practices",
//     price: "₹499",
//     sellerTag: "best seller",

//     rating: 4.7,
//     image:
//       "https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
//   },
//   {
//     id: 3,
//     title: "Node.js Expert",
//     description: "Enterprise-grade Node.js development strategies",
//     price: "₹999",
//     rating: 4.8,
//     sellerTag: "best seller",

//     image:
//       "https://images.unsplash.com/photo-1542903660-eedba2cda473?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
//   },
// ];

// const EBooks = () => {
//   return (
//     <div className="bg-gradient-to-b from-slate-50 to-blue-50 py-20 px-4">
//       <div className="max-w-7xl mx-auto">
//         <div className="text-center mb-16">
//           <h2 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
//             Premium eBooks
//           </h2>
//           <p className="text-xl text-slate-600">
//             Expand your knowledge with our expertly crafted guides
//           </p>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
//           {eBooks.map((book) => (
//             <div
//               key={book.id}
//               className="group relative bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 overflow-hidden"
//             >
//               <div className="relative h-60 overflow-hidden">
//                 <img
//                   src={book.image}
//                   alt={book.title}
//                   className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-t from-slate-900/60 to-transparent" />
//                 <div className="absolute bottom-4 left-4 flex items-center space-x-1">
//                   {[...Array(5)].map((_, i) => (
//                     <svg
//                       key={i}
//                       className={`w-5 h-5 ${
//                         i < Math.floor(book.rating)
//                           ? "text-amber-400"
//                           : "text-slate-200"
//                       }`}
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                     >
//                       <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//                     </svg>
//                   ))}
//                   <span className="text-sm text-white ml-2">{book.rating}</span>
//                   {/* <span className="text-sm text-white ml-2">{book.sellerTag}</span> */}
//                 </div>
//               </div>

//               <div className="p-6">
//                 <h3 className="text-2xl font-bold text-slate-800 mb-3">
//                   {book.title}
//                 </h3>
//                 <p className="text-slate-600 mb-5">{book.description}</p>

//                 <div className="flex items-center justify-between">
//                   <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
//                     {book.price}
//                   </span>
//                   <button className="flex items-center space-x-2 bg-gradient-to-r from-blue-600 to-cyan-500 text-white px-6 py-3 rounded-lg hover:from-blue-700 hover:to-cyan-600 transition-all duration-300">
//                     <svg
//                       className="w-5 h-5"
//                       fill="none"
//                       stroke="currentColor"
//                       viewBox="0 0 24 24"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
//                       />
//                     </svg>
//                     <span onClick={()=>{toast("Book is not Avilable")}}>Get eBook</span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EBooks;

import React from "react";
import IMG1 from "../../assets/portfolio1.png";
import IMG2 from "../../assets/portfolio2.png";
import IMG3 from "../../assets/portfolio3.png";
import IMG4 from "../../assets/portfolio4.png";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.png";
import IMG7 from "../../assets/portfolio7.png";
import IMG8 from "../../assets/portfolio8.png";
import IMG9 from "../../assets/shoping app.png";
const data = [
  {
    id: 1,
    image: IMG1,
    title: "E-commerce Website Amazon Clone ",
    github: "https://github.com/tribanj",
    demo: "https://amazonlandingpagecss.netlify.app/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Restaurent Website ",
    github: "https://github.com/tribanj",
    demo: "https://restauhotel.netlify.app/",
  },
  {
    id: 3,
    image: IMG3,
    title: "New Visual of GPT 3.O Website",
    github: "https://github.com/tribanj",
    demo: "https://gpt3point0.netlify.app/",
  },
  {
    id: 4,
    image: IMG4,
    title: "React Portfolio Design",
    github: "https://github.com/tribanj",
    demo: "https://reactportfoliodesign.netlify.app/",
  },
  {
    id: 5,
    image: IMG5,
    title: "Room Renting Website (Full Stack)",
    github: "https://github.com/tribanj",
    demo: "https://room-marketplace.netlify.app/",
  },
  {
    id: 6,
    image: IMG6,
    title: "Hotel Renting Site Design",
    github: "https://github.com/tribanj",
    demo: "https://tribhuwan-first-react-project.netlify.app/",
  },
  {
    id: 7,
    image: IMG7,
    title: "Personal Portfolio",
    github: "https://github.com/tribanj",
    demo: "https://github.com/tribanj/restaurant",
  },
  {
    id: 8,
    image: IMG8,
    title: "Name Suggestion application",
    github: "https://github.com/tribanj",
    demo: "https://name-suggesting-application.netlify.app/",
  },
  {
    id: 9,
    image: IMG9,
    title: "Online shoping Application",
    github: "https://github.com/tribanj",
    demo: "https://electricworld.netlify.app/",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio" className="bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <h5 className="text-lg text-gray-400 text-center">My Recent Work</h5>
        <h2 className="text-4xl font-bold text-white text-center mb-12">
          Portfolio
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map(({ id, image, title, github, demo }) => (
            <article
              key={id}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="portfolio__item-image">
                <img
                  src={image}
                  alt={title}
                  className="w-full h-48 object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-4">
                  {title}
                </h3>
                <div className="flex space-x-4">
                  <a
                    href={github}
                    className="flex-1 text-center bg-gray-700 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition-colors duration-300"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                  <a
                    href={demo}
                    className="flex-1 text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check The Demo
                  </a>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
