import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { format, subDays } from "date-fns";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

const ApprovedUsersPage = () => {
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [entries, setEntries] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Default: Today
  const [earnings, setEarnings] = useState({});
  const [loading, setLoading] = useState(true);
  const [savingStates, setSavingStates] = useState({}); // Track saving states for each user

  // Fetch approved users and their data
  useEffect(() => {
    const fetchApprovedUsers = async () => {
      try {
        setLoading(true);
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((user) => user.approved);

        setApprovedUsers(usersData);

        // Fetch earnings and entries for each user
        usersData.forEach((user) => {
          fetchEarnings(user.id);
          fetchEntriesForDate(user.id, selectedDate);
        });
      } catch (error) {
        toast.error("Failed to load users: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApprovedUsers();
  }, [selectedDate]);

  // Fetch entries for a specific date
  const fetchEntriesForDate = async (userId, date) => {
    const entryRef = doc(db, "entries", userId, "dailyEntries", date);
    const entrySnap = await getDoc(entryRef);

    if (entrySnap.exists()) {
      const entryData = entrySnap.data();
      setEntries((prev) => ({ ...prev, [userId]: entryData.count }));
      updateEarnings(userId, entryData.count, "today");
    } else {
      setEntries((prev) => ({ ...prev, [userId]: 0 }));
      updateEarnings(userId, 0, "today");
    }
  };

  // Fetch all-time and last 7 days earnings
  const fetchEarnings = async (userId) => {
    let last7DaysEarnings = 0;
    let allTimeEarnings = 0;

    // Calculate last 7 days earnings
    for (let i = 0; i < 7; i++) {
      const date = format(subDays(new Date(), i), "yyyy-MM-dd");
      const entryRef = doc(db, "entries", userId, "dailyEntries", date);
      const entrySnap = await getDoc(entryRef);

      if (entrySnap.exists()) {
        const entryData = entrySnap.data();
        last7DaysEarnings += entryData.count * 4;
      }
    }

    // Calculate all-time earnings
    const entriesCollection = collection(db, "entries", userId, "dailyEntries");
    const allEntriesSnap = await getDocs(entriesCollection);
    allEntriesSnap.forEach((doc) => {
      const entryData = doc.data();
      allTimeEarnings += entryData.count * 4;
    });

    setEarnings((prev) => ({
      ...prev,
      [userId]: {
        today: prev[userId]?.today || 0,
        last7Days: last7DaysEarnings,
        allTime: allTimeEarnings,
      },
    }));
  };

  // Handle entry input change
  const handleEntryChange = (userId, value) => {
    const numEntries = Math.min(Math.max(Number(value), 0), 100);
    setEntries((prev) => ({ ...prev, [userId]: numEntries }));
  };

  // Save entries and update earnings
  const saveEntries = async (userId) => {
    if (!entries[userId]) return;

    setSavingStates((prev) => ({ ...prev, [userId]: true }));
    const entryRef = doc(db, "entries", userId, "dailyEntries", selectedDate);

    try {
      await setDoc(
        entryRef,
        { count: entries[userId], date: selectedDate },
        { merge: true }
      );
      toast.success(`Entries updated for ${selectedDate}!`);
      fetchEntriesForDate(userId, selectedDate);
      fetchEarnings(userId);
    } catch (error) {
      toast.error("Failed to update entries: " + error.message);
    } finally {
      setSavingStates((prev) => ({ ...prev, [userId]: false }));
    }
  };

  // Update earnings based on entries
  const updateEarnings = (userId, entryCount, type) => {
    setEarnings((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [type]: entryCount * 4,
      },
    }));
  };

  // Loading Skeleton Component
  const SkeletonRow = () => (
    <tr className="animate-pulse">
      {[...Array(7)].map((_, i) => (
        <td key={i} className="p-4">
          <div className="h-4 bg-gray-600 rounded"></div>
        </td>
      ))}
    </tr>
  );

  return (
    <div className="p-6 bg-gray-900 min-h-screen text-white">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <h1 className="text-3xl font-bold mb-4 md:mb-0">
            User Earnings Dashboard
          </h1>
          <div className="bg-gray-800 p-3 rounded-lg">
            <label className="mr-2 font-medium text-gray-300">
              Select Date:
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="px-4 py-2 rounded bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-blue-500 outline-none"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>

        {loading ? (
          <div className="overflow-x-auto rounded-lg border border-gray-700">
            <table className="w-full">
              <thead className="bg-gray-800">
                <tr>
                  {[...Array(7)].map((_, i) => (
                    <th key={i} className="p-4 text-left">
                      <div className="h-5 bg-gray-600 rounded w-3/4"></div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(5)].map((_, i) => (
                  <SkeletonRow key={i} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="overflow-x-auto rounded-lg border border-gray-700 shadow-xl">
            <table className="w-full">
              <thead className="bg-gray-800">
                <tr>
                  <th className="p-4 text-left">User</th>
                  <th className="p-4 text-left">Email</th>
                  <th className="p-4 text-left">Daily Entries</th>
                  <th className="p-4 text-left">Today's Earnings</th>
                  <th className="p-4 text-left">Weekly Earnings</th>
                  <th className="p-4 text-left">Total Earnings</th>
                  <th className="p-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {approvedUsers.map((user) => (
                  <motion.tr
                    key={user.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="hover:bg-gray-800/50 transition-colors"
                  >
                    <td className="p-4 font-medium">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="p-4 text-gray-300">{user.email}</td>
                    <td className="p-4">
                      <div className="relative w-24">
                        <input
                          type="number"
                          value={entries[user.id] || ""}
                          onChange={(e) =>
                            handleEntryChange(user.id, e.target.value)
                          }
                          className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:ring-2 focus:ring-blue-500 outline-none"
                          min="0"
                          max="100"
                        />
                        <span className="absolute right-3 top-2 text-gray-400 text-sm">
                          /100
                        </span>
                      </div>
                    </td>
                    <td className="p-4">
                      <span className="px-3 py-1 bg-green-500/20 text-green-400 rounded-full text-sm">
                        ₹{(earnings[user.id]?.today || 0).toLocaleString()}
                      </span>
                    </td>
                    <td className="p-4">
                      <span className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm">
                        ₹{(earnings[user.id]?.last7Days || 0).toLocaleString()}
                      </span>
                    </td>
                    <td className="p-4">
                      <span className="px-3 py-1 bg-purple-500/20 text-purple-400 rounded-full text-sm">
                        ₹{(earnings[user.id]?.allTime || 0).toLocaleString()}
                      </span>
                    </td>
                    <td className="p-4">
                      <button
                        onClick={() => saveEntries(user.id)}
                        disabled={savingStates[user.id]}
                        className={`px-4 py-2 rounded-md transition-all ${
                          savingStates[user.id]
                            ? "bg-gray-600 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-700"
                        }`}
                      >
                        {savingStates[user.id] ? (
                          <div className="flex items-center">
                            <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div>
                            Saving...
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && approvedUsers.length === 0 && (
          <div className="text-center py-12 text-gray-400">
            No approved users found
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedUsersPage;
