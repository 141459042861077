// import React from "react";
// import { Navigate } from "react-router-dom";

// const ProtectedRoute = ({ user, children }) => {
//   if (!user) {
//     return <Navigate to="/login" />;
//   }
//   return children;
// };

// export default ProtectedRoute;
import React, { useState, useEffect } from "react";
import { db } from "./components/firebaseConfig";
import { Navigate, useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoadingSpinner from "./components/LoadingSpinner";
import NotAuthorized from "./components/notauthorised/NotAuthorised";

const ProtectedRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setIsAuthenticated(false);
        setIsAuthorized(false); // No user, so not authorized
        return;
      }

      setIsAuthenticated(true);

      // Admin always gets access
      if (user.email === "admin@ambeynexus.com") {
        setIsAuthorized(true);
        return;
      }

      // Only check approval for "/userwoDataEntryworksforms"
      if (location.pathname === "/userwoDataEntryworksforms") {
        const userDocRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().approved) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error("Error fetching user approval status:", error);
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(true); // Allow access to all other pages
      }
      
      // Only check approval for "/form-for-data-entry"
      if (location.pathname === "/form-for-data-entry") {
        const userDocRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().approved) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error("Error fetching user approval status:", error);
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(true); // Allow access to all other pages
      }

    });

    return () => unsubscribe();
  }, [auth, location.pathname]);

  if (isAuthorized === null) {
    return <LoadingSpinner />; // Show loading state while checking
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirect to login if user is not logged in
  }

  return isAuthorized ? children : <NotAuthorized />;
};

export default ProtectedRoute;
