import React, { useState, useEffect } from "react";

const messages = [
  "🎉 Welcome, Kanishka! Congratulations on your joining!",
  "🚀 You did it! Completing the interview is just the start of your journey!",
  "🎉 Welcome, Manmeet ! Congratulations on your joining!",
  "🚀 You did it! Completing the interview is just the start of your journey!",
  "🎉 Welcome, Kanchan ! Congratulations on your joining!",
  "🚀 You did it! Completing the interview is just the start of your journey!",
  "🎉 Welcome, Mr. Ankit! Congratulations on your joining!",
  "🔥 Wishing you great success in this new opportunity!",
  "🎉 Welcome, Mr. Gautam! Congratulations on your joining!",
  "🎉 Welcome, Shanu! Congratulations on your joining!",
  "🌟 Keep shining! The best is yet to come!",
  "🎉 Welcome, Mr. Anand! Congratulations on your joining!",
  "🎉 Welcome, Mr. Vishal! Congratulations on your joining!",
  "🥳 Cheers to new beginnings and endless achievements!",
];

const CongratulationCard = () => {
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 3000); // Change message every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full flex justify-center">
      <div
        className="w-[90%] md:w-[70%] bg-gradient-to-r from-blue-500 to-purple-500 text-white text-center p-6 rounded-lg shadow-xl 
                      text-2xl md:text-3xl font-bold transition-all duration-500 animate-fadeInUp 
                      font-sans md:font-serif italic tracking-wide"
      >
        <p key={currentMessage} className="transition-all duration-500">
          {messages[currentMessage]}
        </p>
      </div>
    </div>
  );
};

export default CongratulationCard;
