import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const WhatsAppPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <AnimatePresence>
      {showPopup && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[999]"
        >
          <motion.div
            initial={{ scale: 0.8, y: 50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, opacity: 0 }}
            className="relative bg-gradient-to-br from-[#1f1c2c] to-[#928dab] rounded-2xl shadow-2xl overflow-hidden w-[350px]"
          >
            {/* Close Button */}
            <button
              onClick={handleClosePopup}
              className="absolute top-4 right-4 text-white/80 hover:text-white transition-colors z-10"
            >
              <AiOutlineClose size={24} />
            </button>

            {/* Content */}
            <div className="p-8 text-center">
              {/* WhatsApp Icon */}
              <div className="relative inline-block mb-6">
                <div className="absolute inset-0 bg-white/10 blur-lg rounded-full"></div>
                <FaWhatsapp className="relative text-6xl text-[#25D366] z-10" />
              </div>

              {/* Text Content */}
              <h2 className="text-2xl font-bold text-white mb-4">
                Stay Connected!
              </h2>
              <p className="text-white/80 mb-6 leading-relaxed">
                Get instant updates on <span className="font-semibold text-white">job openings</span> and <span className="font-semibold text-white">interview schedules</span> via WhatsApp.
              </p>

              {/* WhatsApp Number */}
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-3 bg-[#25D366] hover:bg-[#128C7E] text-white font-semibold px-6 py-3 rounded-full transition-all transform hover:scale-105"
                aria-disabled
              >
                <FaWhatsapp className="text-xl" />
                <span>+91 78892 74462</span>
              </a>
            </div>

            {/* Glow Effect */}
            <div className="absolute -top-32 -left-32 w-64 h-64 bg-[#25D366]/20 rounded-full blur-3xl"></div>
            <div className="absolute -bottom-32 -right-32 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl"></div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WhatsAppPopup;