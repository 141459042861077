import React, { useEffect, useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components that are small or always needed can be imported normally
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/utility/ScrollTop";
import LoadingSpinner from "./components/LoadingSpinner";
import WhatsAppPopup from "./components/popUp/WhatsappPopUp";
import Marquee from "react-fast-marquee";
import ProtectedRoute from "./ProtectedRoute";
import NotAuthorized from "./components/notauthorised/NotAuthorised.js";
import Portfolio from "./components/products/EBooks";
import GoogleForms from "./components/dataentrywork/GoogleForms.js";
import DynamicForm from "./components/dataentrywork/dataentryform/DynamicForm.js";
import ApprovedUsersPage from "./components/admin/ApprovedUsersPage.js";


// Lazy load the rest of the routes for better performance
const HomePage = React.lazy(() => import("./components/home/HomePage"));
const AboutUs = React.lazy(() => import("./components/about/AboutUs"));
const Contact = React.lazy(() => import("./components/contact/Contact"));
const Seo = React.lazy(() => import("./components/services/seo/Seo"));
const WevDev = React.lazy(() =>
  import("./components/services/development/WevDev")
);
const Gd = React.lazy(() =>
  import("./components/services/graphicsDesigning/Gd")
);
const Services = React.lazy(() => import("./components/services/Servises"));
const Training = React.lazy(() =>
  import("./components/services/training/Training")
);
const ComputerBasics = React.lazy(() =>
  import("./components/services/computerBasics/ComputerBasics")
);
const SpokenEnglishCoursePage = React.lazy(() =>
  import("./components/services/training/SpokenEnglishCoursePage")
);
const PDPCoursePage = React.lazy(() =>
  import("./components/services/training/PDPCoursePage .js")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/privacy/PrivacyPolicy")
);
const FAQPage = React.lazy(() => import("./components/faq/FaqPage"));
const Signup = React.lazy(() => import("./components/auth/Signup"));
const Login = React.lazy(() => import("./components/auth/Login"));
const Work = React.lazy(() => import("./components/work/Work"));
const Profile = React.lazy(() => import("./components/profile/Profile"));
const JobPostForm = React.lazy(() => import("./components/work/JobPostForm"));
const Blogs = React.lazy(() => import("./components/blog/Blogs"));
const BlogDetailPage = React.lazy(() =>
  import("./components/blog/BlogDetailPage")
);
const AdminDashboard = React.lazy(() =>
  import("./components/admin/AdminDashboard")
);
const CourseEnrollment = React.lazy(() =>
  import("./components/services/training/CourseEnrollment")
);
// const EBooks = React.lazy(() => import("./components/products/EBooks"));

const App = () => {
  const [user, setUser] = useState(null); // Track logged-in user
  const [loading, setLoading] = useState(true); // Track auth state loading
  const auth = getAuth();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    // Show a loader while checking auth state
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <LoadingSpinner className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }

  // function NotAuthorized() {
  //   return (
  //     <div className="flex items-center justify-center min-h-screen bg-gray-100">
  //       <h1 className="text-xl font-semibold text-red-600">
  //         You are not authorised
  //       </h1>
  //     </div>
  //   );
  // }

  return (
    <Router>
      <div>
      <ToastContainer />
        {/* Pass dynamic auth state to Navbar */}
        <Navbar
          isLoggedIn={!!user}
          userName={
            user?.displayName ||
            (user?.email ? user.email.charAt(0).toUpperCase() : "")
          }
        />
        <ScrollToTop />
        <Marquee
          gradient={false}
          speed={60}
          className="bg-gray-950 py-3 shadow-lg"
        >
          <div className="flex items-center space-x-8 px-4">
            <span className="font-['Oswald'] text-2xl font-bold text-orange-400 uppercase tracking-wider animate-pulse">
              ⚠️ IMPORTANT NOTICE:
            </span>
            <div className="flex items-center space-x-6">
              <span className="font-['Poppins'] text-lg font-semibold text-white">
                <span className="text-orange-400">Ambey Nexus</span> Never
                Charges Any Fees for Job Opportunities!
              </span>
              <div className="h-8 w-1 bg-white"></div>
              <div className="flex items-center space-x-3">
                <span className="font-['Lato'] text-xl text-white">
                  📱 Connect on WhatsApp:
                </span>
                <span className="font-['Montserrat'] text-xl font-bold bg-white/20 px-4 py-1 rounded-full text-white hover:bg-white/30 transition-all duration-300">
                  <span className="text-orange-400">👉</span> 78892 74462
                </span>
              </div>
              <div className="h-8 w-1 bg-white"></div>
              <span className="font-['Bebas_Neue'] text-2xl text-white tracking-wide">
                💡 Send 'Hi' for Latest Job Alerts 💡
              </span>
            </div>
          </div>
        </Marquee>

        <main className="p-4">
          <Suspense
            fallback={
              <LoadingSpinner className="w-16 h-16 text-blue-500 animate-spin" />
            }
          >
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/training" element={<Training />} />
              <Route path="/courses/web-development" element={<WevDev />} />
              <Route
                path="/training/social-media-marketing"
                element={<Seo />}
              />
              <Route path="/courses/graphic-design" element={<Gd />} />
              <Route
                path="/courses/computer-basics"
                element={<ComputerBasics />}
              />
              <Route
                path="/courses/spoken-english-courses"
                element={<SpokenEnglishCoursePage />}
              />
              <Route
                path="/courses/personality-development-program"
                element={<PDPCoursePage />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/blog/:id" element={<BlogDetailPage />} />
              <Route
                path="/career"
                element={
                  <ProtectedRoute user={user}>
                    <Work />
                  </ProtectedRoute>
                }
              />
              <Route path="/demo-work" element={<Portfolio />} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute user={user}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-job-post"
                element={
                  <ProtectedRoute user={user}>
                    <JobPostForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute user={user}>
                    {user && user.email === "admin@ambeynexus.com" ? (
                      <AdminDashboard />
                    ) : (
                      <NotAuthorized />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/all-approved-user-list"
                element={
                  <ProtectedRoute user={user}>
                    {user && user.email === "admin@ambeynexus.com" ? (
                      <ApprovedUsersPage />
                    ) : (
                      <NotAuthorized />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/enroll/:id"
                element={
                  <ProtectedRoute user={user}>
                    <CourseEnrollment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/userwoDataEntryworksforms"
                element={
                  <ProtectedRoute>
                    <DynamicForm />
                  </ProtectedRoute>
                }
              />
            
              <Route
                path="/form-for-data-entry"
                element={
                  <ProtectedRoute>
                    <GoogleForms />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
          
          <WhatsAppPopup />
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
